<template lang="pug">
  v-container
    v-toolbar(
      color="primary"
      dark
      elevation="0"
    )
      span New Feed Detail
      v-spacer
      v-icon mdi-alpha-d-box

    v-container(
      style="max-width: 800px;"
    )
      v-row
        v-col.pb-0(
          cols="12"
        )
          v-autocomplete(
            label="Stock"
            outlined
            dense
            :items="stockList"
            :loading="stockGetting"
            v-model="stockId"
            item-value="id"
            :filter="autocompleteFilter(['id', 'product_name'])"
          )
            template(v-slot:selection="{ item }")
              span.primary--text {{ item.id }}
              //- span.blue-grey--text.mx-1.text-caption {{ item.description }}
            template(v-slot:item="{ item }")
              span.primary--text {{ item.id }}
              span.blue-grey--text.mx-1.text-caption {{ item.brand_name }} {{ item.description }}
        v-col.py-0(
          cols="12"
        )
          v-text-field(
            outlined
            dense
            type="number"
            label="Size"
            v-model="size"
          )
        v-col.py-0(
          lg="6"
          cols="12"
        )
          v-text-field(
            outlined
            dense
            type="number"
            label="Cost / Bag"
            v-model="perBag"
          )
        v-col.py-0(
          lg="6"
          cols="12"
        )
          v-text-field(
            outlined
            dense
            type="number"
            label="Cost / Kilo"
            v-model="perKilo"
          )
        v-col.py-0(
          lg="6"
          cols="12"
        )
          v-text-field(
            outlined
            dense
            type="number"
            label="Resell / Bag"
            v-model="resellPerBag"
          )
        v-col.py-0(
          lg="6"
          cols="12"
        )
          v-text-field(
            outlined
            dense
            type="number"
            label="Resell / Kilo"
            v-model="resellPerKilo"
          )
        v-col.py-0(cols="12")
          v-btn(
            block
            elevation="0"
            color="primary"
            dark
            :loading="stockFdDtlPosting"
            @click="storeStockFdDtl"
          )
            span Save
</template>
<script>
import stockRepository from '@/repositories/stock.repository'
import stockFdDtlRepository from '@/repositories/stock-feed-detail.repository'
import { getVars, postVars, errorHandler } from '@/libs/api-helper.extra'
import searchDelay from '@/libs/searchDelay.extra'
import { mapState } from 'vuex'

const getStockDelay = searchDelay()
const postFdPrmDelay = searchDelay()

const inputVars = () => ({
  stockId: null,
  size: null,
  perBag: null,
  perKilo: null,
  resellPerBag: null,
  resellPerKilo: null,
})

export default {
  name: 'EditFeedDetail',
  created () {
    this.getStock()
    this.websocketEvents()
  },
  data () {
    return {
      ...inputVars(),
      ...getVars('stock'),
      ...postVars('stock-fd-dtl'),
      successFdPrm: null,
    }
  },
  computed: {
    ...mapState({
      echo: state => state.websocket.echo,
    }),
  },
  methods: {
    getStock () {
      if (this.stockGetting) return
      this.stockGetting = true
      this.stockGetErrors = []
      getStockDelay(() => {
        const params = this.getStockIndexParams()
        stockRepository.index(params)
          .then(({ data }) => { this.stockList = data })
          .catch(e => { this.stockGetErrors = errorHandler(e) })
          .then(() => { this.stockGetting = false })
      })
    },
    getStockIndexParams () {
      return {
        noHas: ['feedDetail', 'defaultDetail'].join(','),
      }
    },
    storeStockFdDtl () {
      if (this.stockFdDtlPosting) return
      this.stockFdDtlPosting = true
      this.resetMessages('successMessage', null)
      this.resetMessages('errorMessages', [])
      postFdPrmDelay(() => {
        const data = this.getInputData()
        stockFdDtlRepository.store(data)
          .then(this.stockFdDtlSuccess)
          .catch(e => { this.$emit('error', errorHandler(e)) })
          .then(() => { this.stockFdDtlPosting = false })
      })
    },
    getInputData () {
      const data = {}
      Object.keys(inputVars()).forEach(e => {
        data[e.camelToSnakeCase()] = this[e]
      })
      return data
    },
    stockFdDtlSuccess ({ data }) {
      if (!data.stock_id) return
      this.$emit('success', `New Feeding Detail for ${data.stock_id} has been stored!`)
      this.reset(inputVars())
    },
    resetMessages (varName, val) {
      this.$emit('reset', varName, val)
    },
    reset (defaults) {
      Object.keys(defaults).forEach(e => {
        this[e] = defaults[e]
      })
    },
    websocketEvents () {
      this.echo.private('database.events')
        .listen('DBStoreEvent', this.dbStoreEvent)
    },
    dbStoreEvent ({ model, data }) {
      if ([!model, this.$objectEmpty(data)].or()) return
      if (model === 'StockFeedDetail') {
        const stockList = this.stockList.map(e => Object.assign({ ...e }))
        this.stockList = stockList.objectInsertion(data, 'id', 'id')
      }
    },
    autocompleteFilter (fields) {
      return (item, queryText, itemText) => {
        const filters = fields.map(e => item[e])
        const rxItem = new RegExp(filters.join(), 'i')
        const rxQueryText = new RegExp(queryText, 'i')
        return rxQueryText.test(rxItem)
      }
    },
  },
}
</script>
